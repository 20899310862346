import { Button, DatePicker, Radio, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from 'styles/components/leaderboardFilters.module.scss';
import {
  DATE_FILTERS,
  FILTERS_SEARCH_PARAMS,
  FILTER_TYPE,
} from 'utils/constants';
import ProfileContext from './profileContext';
import { getStartEndDateFromDateFilter } from 'utils/utils';
import { shortnamesToUnicode } from 'emojitsu';
import { DownloadOutlined } from '@ant-design/icons';
import { exportRecognitions } from 'utils/api';
import moment from 'moment';
const { RangePicker } = DatePicker;

const LeaderboardFilters = ({ filterAndSort = () => {}, fetch = () => {} }) => {
  const { profile } = useContext(ProfileContext);
  const [currentQueryParameters, setSearchParams] = useSearchParams();
  const [typePoints, setValueTypePoints] = useState(FILTER_TYPE.ALL);
  const [valueFilter, setValueFilter] = useState(FILTER_TYPE.ALL);
  const [dateFilter, setDateFilter] = useState(DATE_FILTERS.ALL_TIMES);

  useEffect(() => {
    setValueTypePoints(
      currentQueryParameters.get(FILTERS_SEARCH_PARAMS.TYPE) || FILTER_TYPE.ALL
    );
    setValueFilter(
      currentQueryParameters.get(FILTERS_SEARCH_PARAMS.VALUE) || FILTER_TYPE.ALL
    );
    setDateFilter(
      currentQueryParameters.get(FILTERS_SEARCH_PARAMS.DATE) ||
        DATE_FILTERS.ALL_TIMES
    );
  }, [currentQueryParameters]);
  const onChangeTypePoints = ({ target: { value } }) => {
    setValueTypePoints(value);
    currentQueryParameters.set(FILTERS_SEARCH_PARAMS.TYPE, value);
    setSearchParams(currentQueryParameters);
    filterAndSort();
  };

  const onChangeValue = (value) => {
    setValueFilter(value);
    currentQueryParameters.set(FILTERS_SEARCH_PARAMS.VALUE, value);
    setSearchParams(currentQueryParameters);
    filterAndSort();
  };

  const onChangeDate = (value) => {
    setDateFilter(value);
    currentQueryParameters.set(FILTERS_SEARCH_PARAMS.DATE, value);
    setSearchParams(currentQueryParameters);
    if (value !== DATE_FILTERS.CUSTOM) {
      fetch(getStartEndDateFromDateFilter(value));
    }
  };

  const onChangeCustom = (date, datestring) => {
    fetch({
      startDate: `${datestring[0]}T00:00:00.000Z`,
      endDate: `${datestring[1]}T23:59:59.999Z`,
    });
  };

  const exportResults = () => {
    const dates = getStartEndDateFromDateFilter(dateFilter);
    exportRecognitions(dates)
      .then((results) => {
        const url = window.URL.createObjectURL(new Blob([results.data]));
        const link = document.createElement('a');
        link.href = url;
        const name = `export_recognitions_${moment().format(
          'MM-DD-YYYY-HHhh'
        )}.xlsx`;
        link.setAttribute('download', name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.filterElement}>
          <div className={styles.label}>Type</div>
          <Radio.Group
            size="small"
            options={[
              { label: 'Received', value: 'received' },
              { label: 'Given', value: 'given' },
              { label: 'Total', value: 'all' },
            ]}
            buttonStyle="solid"
            onChange={onChangeTypePoints}
            value={typePoints}
            optionType="button"
            style={{ minWidth: 220 }}
          />
        </div>
        <div className={styles.filterElement}>
          <div className={styles.label}>Company Value</div>
          <Select
            size="small"
            onChange={onChangeValue}
            popupMatchSelectWidth={false}
            style={{ width: '160px' }}
            value={valueFilter}
            options={[
              { value: 'all', label: 'All' },
              ...(profile?.companyValues?.reduce(
                (acc, element) => [
                  ...acc,
                  {
                    value: element.slug,
                    label: shortnamesToUnicode(element.name),
                  },
                ],
                []
              ) || []),
            ]}
          />
        </div>
        <div className={styles.filterElement}>
          <div className={styles.label}>Date range</div>
          <Select
            size="small"
            onChange={onChangeDate}
            popupMatchSelectWidth={false}
            style={{ width: '150px' }}
            value={dateFilter}
            options={[
              { value: DATE_FILTERS.LAST_7_DAYS, label: 'Last 7 days' },
              { value: DATE_FILTERS.LAST_30_DAYS, label: 'Last 30 days' },
              { value: DATE_FILTERS.LAST_3_MONTH, label: 'Last 3 months' },
              { value: DATE_FILTERS.ALL_TIMES, label: 'All times' },
              { value: DATE_FILTERS.CUSTOM, label: 'Custom' },
            ]}
          />
          {dateFilter === DATE_FILTERS.CUSTOM && (
            <div className={styles.filterElement} style={{ marginLeft: 6 }}>
              <RangePicker
                style={{ width: '230px' }}
                onChange={onChangeCustom}
                size="small"
                allowClear={false}
              />
            </div>
          )}
        </div>

        <div className={styles.export}>
          <Button
            onClick={exportResults}
            icon={<DownloadOutlined />}
            type="primary"
          >
            Export recognitions
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardFilters;
